<template>
  <div class="mb-3">
    <ed-modal v-model="boolExibirCadastroExame" width="80%">
      <div slot="title">
        {{ formData.strNome }} ::
        {{
          formDataExame.intId
            ? "Editar preço do exame"
            : "Inserir preço para novo exame"
        }}
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataExame"
          :handlerSave="salvar"
          disableFixedSave
        >
          <div class="row">
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataExame.intExameId"
              name="intExameId"
              item-text="strNome"
              label="Exame"
              route="Cadastro/Exame"
              rules="required"
              @selected="setExame"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataExame.intCadastroGeralParceiroId"
              label="Parceiro"
              name="intCadastroGeralParceiroId"
              route="Cadastro/CadastroGeral"
              :filters="{
                intTipoCadastroGeralId: 59,
              }"
              @selected="setParceiro"
              :getExamesItems="false"
              :disabled="!formDataExame.intExameId"
            />

            <ed-input-money
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formDataExame.floatValorVenda"
              name="floatValorVenda"
              label="Preço de venda"
              :disabled="!formDataExame.intExameId"
            />

            <ed-input-money
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formDataExame.floatValorCusto"
              name="floatValorCusto"
              label="Preço de custo"
              :disabled="!formDataExame.intExameId"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"
              v-model="formDataExame.boolTabelaPadrao"
              name="boolTabelaPadrao"
              label="Parceiro padrão"
              :disabled="!formDataExame.intExameId"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>
    <ed-simple-table
      :loading="loading"
      :pagination="objPagination"
      @register="onRegister"
      @search="getExames"
      :config="{ registerName: 'Inserir novo exame' }"
    >
      <thead>
        <tr>
          <th>Exame</th>
          <th>Parceiro</th>
          <th>Valor de Venda</th>
          <th>Valor de Custo</th>
          <th>Parceiro Padrão</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(objExame, indexExame) in arrayExames">
          <tr :key="'exame' + objExame.strNome + indexExame">
            <td
              colspan="6"
              class="text-left"
              style="background: #e5ca85; font-weight: bold"
            >
              {{ objExame.strNome }}
            </td>
          </tr>

          <template
            v-for="(objTabelaPrecoExame, indexPreco) in formData.exames"
          >
            <tr
              :key="'preco' + objExame.strNome + indexPreco"
              v-if="objTabelaPrecoExame.intExameId == objExame.intId"
            >
              <td class="text-left">
                <span v-if="objTabelaPrecoExame.intId<=0" style="color:red">
                  Clique em salvar para confirmar a inclusão
                </span>
              </td>

              <td class="text-left">
                <ed-input-auto-complete
                  class="mt-3 mb-3"
                  v-model="objTabelaPrecoExame.intCadastroGeralParceiroId"
                  name="intCadastroGeralParceiroId"
                  route="Cadastro/CadastroGeral"
                  :filters="{
                    intTipoCadastroGeralId: 59,
                  }"
                  :itemList="
                    objTabelaPrecoExame.parceiro
                      ? [objTabelaPrecoExame.parceiro]
                      : []
                  "
                  :initializeItems="false"
                  hideDetails
                  clearable
                />
              </td>
              <td class="text-left">
                <ed-input-money
                  class="mt-2"
                  v-model="objTabelaPrecoExame.floatValorVenda"
                  name="floatValorVenda"
                  hideDetails
                />
              </td>
              <td class="text-left">
                <ed-input-money
                  class="mt-2"
                  v-model="objTabelaPrecoExame.floatValorCusto"
                  name="floatValorCusto"
                  hideDetails
                />
              </td>
              <td class="text-left">
                <ed-input-switch
                  class="mt-2"
                  v-model="objTabelaPrecoExame.boolTabelaPadrao"
                  name="boolTabelaPadrao"
                  hideDetails
                />
              </td>
              <td>
                <ed-button
                  :iconLeft="$utilidade.getIcone('deletar')"
                  color="error"
                  title="Deletar"
                  icon
                  onlyIcon
                  @click="
                    onDelete([indexPreco], objTabelaPrecoExame.intExameId)
                  "
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdForm,
  EdSimpleTable,
  EdModal,
  EdButton,
  EdInputAutoComplete,
  EdInputSwitch,
  EdInputMoney,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdSimpleTable,
    EdModal,
    EdButton,
    EdInputAutoComplete,
    EdInputSwitch,
    EdInputMoney,
  },
  provide() {
    return {};
  },
  mounted() {
    this.getExames();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroExame: false,
      intRegistroInserido: 0,
      objPagination: null,
      formDataExame: {},
      formFilter:{},
      arrayExames: [],
    };
  },
  watch: {
    // formData: {
    //   handler: function (newVal) {
    //     this.getExames();
    //   },
    //   deep: true,
    // },
    "formData.intId"() {
      this.getExames();
    },
  },
  methods: {
    getExames() {
      if (!this.formData.intId) return;

      this.arrayExames = [];

      this.loading = true;

      this.$root.$request
        .get(
          "Cadastro/TabelaPrecoExame",
          Object.assign(this.formFilter, {
            intTabelaPrecoId: this.formData.intId,
            boolAgruparPorExame: true,
          })
        )
        .then((objResult) => {
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            this.objPagination = objResult.result;
            this.arrayExames = objResult.result.data;
          }
        });
    },

    onRegister() {
      this.boolExibirCadastroExame = !this.boolExibirCadastroExame;

      if (!this.formData.exames) {
        this.formData.exames = [];
      }
      this.formDataExame = {
        intId: 0,
        intTabelaPrecoId: this.formData.intId,
        intExameId: null,
        intCadastroGeralParceiroId: null,
        boolTabelaPadrao:
          this.formData.exames && this.formData.exames.length ? 0 : 1,
        floatValorVenda: "0,00",
        floatValorCusto: "0,00",
        exame: null,
        parceiro: null,
      };
    },

    onDelete(arrayIds, intExameId) {
      let self = this;
      this.$root.$dialog
        .confirm(
          arrayIds.length + " registro(s) serão excluídos. Deseja prosseguir?"
        )
        .then(() => {
          arrayIds.forEach((intIndex) => {
            self.formData.exames.splice(intIndex, 1);
          });

          let intIndexExame = self.arrayExames.findIndex(
            (c) => c.intId == intExameId
          );
          let intIndexExamePreco = self.formData.exames.findIndex(
            (c) => c.intExameId == intExameId
          );

          if (intIndexExamePreco == -1 && intIndexExame != -1) {
            self.arrayExames.splice(intIndexExame, 1);
          }

          this.$forceUpdate();
        });
    },

    setExame(obj) {
      this.formDataExame.exame = obj;
    },

    setParceiro(obj) {
      this.formDataExame.parceiro = obj;
    },

    salvar() {
      //INSERIR EXAME PRINCIPAL
      let intIndexExame = this.arrayExames.findIndex(
        (c) => c.intId == this.formDataExame.intExameId
      );

      if (intIndexExame == -1) {
        let objExame = this.formDataExame.exame;
        this.arrayExames.push(objExame);
      } 

      //INSERIR EXAME NA TABELA DE PREÇO
      let intIndexExamePreco = this.formData.exames.findIndex(
        (c) =>
          c.intExameId == this.formDataExame.intExameId &&
          Number(c.intCadastroGeralParceiroId) ==
            Number(this.formDataExame.intCadastroGeralParceiroId)
      );

      if (intIndexExamePreco == -1) {
        this.intRegistroInserido--;

        this.formData.exames.push({
          intId: this.intRegistroInserido,
          intTabelaPrecoId: this.formData.intId,
          intExameId: this.formDataExame.intExameId,
          intCadastroGeralParceiroId:
            this.formDataExame.intCadastroGeralParceiroId,
          boolTabelaPadrao: this.formDataExame.boolTabelaPadrao,
          floatValorVenda: this.formDataExame.floatValorVenda,
          floatValorCusto: this.formDataExame.floatValorCusto,
          exame: this.formDataExame.exame,
          parceiro: this.formDataExame.parceiro,
          boolAtivo: this.formDataExame.boolAtivo,
        });
      }

      this.boolExibirCadastroExame = !this.boolExibirCadastroExame;
    },
  },
};
</script>
