<template>
  <div class=" mb-3">
    <ed-modal v-model="boolExibirCadastroEmpresa" width="80%">
      <div slot="title">
        {{ formData.strNome }} ::
        {{
          formDataEmpresa.intId
            ? "Editar empresa vinculada"
            : "Vincular nova empresa"
        }}
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataEmpresa"
          :handlerSave="salvar"
          disableFixedSave
        >
          <div class="row">
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formDataEmpresa.intCadastroGeralEmpresaId"
              name="intCadastroGeralEmpresaId"
              item-text="strNome"
              label="Empresa"
              route="Cadastro/CadastroGeral"
              rules="required"
              @selected="setCadastroEmpresa"
              :filters="{ intTipoCadastroGeralId: 58 }"
              :initializeItems="false"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formDataEmpresa.intCadastroGeralUnidadeId"
              label="Unidade"
              name="intCadastroGeralUnidadeId"
              route="Cadastro/CadastroGeralUnidade"
              rules="required"
              :filters="{
                intCadastroGeralEmpresaId:
                  this.formDataEmpresa.intCadastroGeralEmpresaId,
              }"
              @selected="setCadastroUnidade"
              :disabled="!formDataEmpresa.intCadastroGeralEmpresaId"
              initializeItems
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Vincular nova empresa' }"
      disabledSearchRows
    />

  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdAlert,
  EdModal,
  EdButton,
  EdInputAutoComplete,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdAlert,
    EdTable,
    EdModal,
    EdButton,
    EdInputAutoComplete,
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroEmpresa: false,
      arrayHeaders: [
        {
          text: "CNPJ",
          sortable: true,
          value: "strCnpj",
        },
        {
          text: "Empresa",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Unidade",
          sortable: true,
          value: "strUnidade",
        },
      ],
      arrayRows: [],
      formDataEmpresa: {
        intId: 0,
        intCadastroGeralEmpresaId: null,
        intCadastroGeralUnidadeId: null,
        empresa: null,
        unidade: null,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.empresas) {
        this.formData.empresas = [];
      }

      let arrayRows = [];

      this.formData.empresas.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            intCadastroGeralEmpresaId: objModel.intCadastroGeralEmpresaId,
            strCnpj: objModel.empresa.strCnpj,
            strNome: objModel.empresa.strNome,
            strUnidade: objModel.unidade ? objModel.unidade.strNome : "---",
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;

      this.formDataEmpresa = {
        intId: 0,
        intCadastroGeralEmpresaId: null,
        intCadastroGeralUnidadeId: null,
        empresa: null,
        unidade: null,
      };
    },

    onEdit(data) {
      this.formDataEmpresa = Object.assign(data, {});
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;

      this.$forceUpdate();
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let indexCadastroEmail = self.formData.empresas.findIndex(
          (c) => c.intId == intId
        );

        if (indexCadastroEmail != -1) {
          self.formData.empresas.splice(indexCadastroEmail, 1);
        }
      });
    },

    setCadastroEmpresa(objEmpresa) {
      this.formDataEmpresa.empresa = objEmpresa;
    },

    setCadastroUnidade(objEmpresa) {
      this.formDataEmpresa.unidade = objEmpresa;
    },

    salvar() {
      let indexEmpresa = this.formData.empresas.findIndex(
        (c) =>
          c.intCadastroGeralEmpresaId ==
            this.formDataEmpresa.intCadastroGeralEmpresaId &&
          c.intCadastroGeralUnidadeId ==
            this.formDataEmpresa.intCadastroGeralUnidadeId
      );

      if (indexEmpresa == -1) {
        this.intRegistroInserido--;

        this.formData.empresas.push({
          intId: this.intRegistroInserido,
          intTabelaPrecoId: this.formData.intId,
          intCadastroGeralEmpresaId:
            this.formDataEmpresa.intCadastroGeralEmpresaId,
          intCadastroGeralUnidadeId:
            this.formDataEmpresa.intCadastroGeralUnidadeId,
          empresa: this.formDataEmpresa.empresa,
          unidade: this.formDataEmpresa.unidade,
        });
      }

      this.initialize();
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;
    },
  },
};
</script>
