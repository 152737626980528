<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-empresa">
        Unidade de Trabalho
        <i :class="$utilidade.getIcone('empresa') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-exame">
        Preço dos Exames
        <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral :formData="formData" v-show="strTab == 'tab-cadastro'" />
      <cadastro-empresa :formData="formData" v-show="strTab == 'tab-empresa'" />
      <cadastro-exame :formData="formData" v-show="strTab == 'tab-exame'" />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral";
import CadastroExame from "./partials/exame";
import CadastroEmpresa from "./partials/empresa";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intTabelaPrecoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    CadastroExame,
    CadastroEmpresa,
  },
  mounted() {
    this.getTabelaPreco();
  },
  created() {},
  data() {
    return {
      formData: {
        intId: 0,
        exames: [],
        boolAtivo: 1,
      },
      strTab: "tab-cadastro",
    };
  },
  watch: {
    intTabelaPrecoId() {
      this.getTabelaPreco();
    },
  },
  methods: {
    getTabelaPreco() {
      if (!this.intTabelaPrecoId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/TabelaPreco", { intId: this.intTabelaPrecoId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let formData = Object.assign(objResponse.result, {});
            this.formData = formData;
          }
        });
    },

    salvar() {
      this.$root.$request
        .post("Cadastro/TabelaPreco", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name != "cadastro.preco.edit") {
              this.$router.push({
                name: "cadastro.preco.edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.getTabelaPreco();
            }
          }
        });
    },
  },
};
</script>
